import { useEffect, useContext, useMemo, useCallback, useState, useRef } from "react";
import Head from "next/head";
import { Banner } from "../layout/banners/banner/banner";
import MainContentArea from "../layout/maincontentarea/MainContentArea";
import MobileNavigation from "../components/menu/mobile-navigation";
import { ProductsProvider } from "../contexts/ProductsContext";
import { UserContext } from "../contexts/UserContext";
import { Categories } from "../components/categories/categories";
import { NewItems } from "../components/newItems/newItems";
import RecentDropSearchBox from "../components/searchBar/searchbar";
import dynamic from "next/dynamic";
import { ArtistProfile } from "../models/user";
import { SearchProvider } from "../contexts/typesense/SearchContext";
import MapMobileView, {
  AllArtistSlider,
} from "../components/map-mobile-view/map-mobile-view";
import useGetDeviceType from "../hooks/useGetDeviceType";
import ArtistProducts from "../components/home/artist-products";
import { TypesenseProduct } from "../models/product";
import Image from "next/image";
import { useRouter } from "next/router";
import UseWindowSize from "../hooks/helper/useWindowSize";
import { MerchWrapper, OffersWrapper } from "../styles/offers-style";
import Link from "next/link";
import artistBg from "../public/images/banner/artist.jpg";
import artOrgBg from "../public/images/banner/artorg.jpg";
import eventsBg from "../public/images/banner/events.jpg";
import daoBg from "../public/images/banner/dao.jpg";
import marketplaceBg from "../public/images/banner/marketplace.png";
import { fleatoConfig } from "../fleato-config";
import photographyImg from "../public/images/assets/photography.jpg";
import collectibles from "../public/images/assets/collectibles-hb.jpg";
import lovinaBg from "../public/images/assets/lovina-hb.png";
import { Agency } from "../models/agency";
import { auth } from "../firebaseutil";

import savetheartistBG from '../public/images/card/saveartist-card-img.jpg';
import thunderballBG from '../public/images/card/thunderball-card-img.jpg';
import fleatoArtistBG from '../public/images/card/fleato-artist-card.jpg';
import fleatoEventBG from '../public/images/card/fleato-event-card.jpg';
import bluetileBG from '../public/images/card/bluetile-card.jpg';
import betirriBG from '../public/images/card/betirri-card.jpg';
import seshBG from '../public/images/card/save-artist-card.jpg';
import freshartsBG from '../public/images/card/fresharts-card-img.jpg'
import reggaeBG from '../public/images/card/reggae-card-img.jpg';


interface Props {
  allArtists: ArtistsApiResponse[];
  featuredArtists: ArtistsApiResponse[];
  agency: Agency;
  groups: HomePageGroups;
  setClearSearchBox: (b: boolean) => void;
  clearSearchBox: boolean;
}

const Home = () => {
  return (<div>
    Fleato.com is shutdown. 
  </div>);
}

const HomeOld = ({
  allArtists,
  featuredArtists,
  agency,
  groups,
  setClearSearchBox,
  clearSearchBox,
}: Props) => {
  const {
    isAuthenticated,
    user,
    dbUser,
    signInSignUpInProgress,
    openLoginPopup,
  } = useContext(UserContext);
  const artistsWithGeo = allArtists
    ?.filter((a) => a?.artist?.locationLatLng?.length == 2)
    .map((a) => a.artist);
  const screenProps = UseWindowSize();
  const router = useRouter();
  const [localUser, setLocalUser] = useState({
    userName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    (async () => {
      const userName = await localStorage.getItem("userName");
      const email = await localStorage.getItem("email");
      const phone = await localStorage.getItem("phone");
      await setLocalUser({
        userName:
          userName != undefined && userName != "undefined" ? userName : "",
        email: email != undefined && email != "undefined" ? email : "",
        phone: phone != undefined && phone != "undefined" ? phone : "",
      });
    })();
  }, [dbUser]);

  const { isMobile } = useGetDeviceType();

  useEffect(() => {
    if (signInSignUpInProgress) {
      openLoginPopup();
    }
  }, [signInSignUpInProgress]);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log(scrollY)
  const date = new Date()
  const GroupCard = ({
    title,
    item,
  }: {
    title: string;
    item: HomePageGroupItem;
  }) => {
    return (
      <div className="col-sm-4 bg-white offer-card border my-2 my-lg-0">
        <p className="offer-title">{title}</p>
        <div className="offer-gallery d-flex justify-contnent-between flex-wrap">
          <div className="offer-gallery-row-1">
            {item?.rowOne.map((value, index) => (
              <Link
                href={`/categories/${value.tag}`}
                passHref
                key={value.productName}
              >
                <div
                  className="offer-item cursor-pointer"
                  key={index}
                  onClick={() => setClearSearchBox(true)}
                >
                  <Image
                    src={value?.productImage}
                    layout={"intrinsic"}
                    alt={"saree"}
                    width={120}
                    height={120}
                    objectFit={"contain"}
                  />
                  <p className="offer-name">{value?.productName}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="offer-gallery-row-2">
            {item?.rowTwo.map((value, index) => (
              <Link
                href={`/categories/${value.tag}`}
                passHref
                key={value.productName}
              >
                <div className="offer-item cursor-pointer" key={index}>
                  <Image
                    src={value?.productImage}
                    layout={"intrinsic"}
                    alt={"saree"}
                    width={120}
                    height={120}
                    objectFit={"contain"}
                  />
                  <p className="offer-name">{value?.productName}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const MapComponent = useMemo(
    () => dynamic(() => import("../components/map/map"), { ssr: false }),
    []
  );
  const cardRef = useRef<HTMLDivElement>(null);
  return (
    <div className="container-fluid p-0">
      <Head>
        <title>Fleato - Web 3.0 Global Marketplace for Art &amp; Fashion</title>
        <meta
          name="description"
          content="Become a part of the Fleato, the Web 3.0 Global Marketplace for Art &amp; Fashion, and earn Fleato rewards and NFTs"
        />
        <meta
          property="og:title"
          content={"Fleato - Web 3.0 Global Marketplace for Art & Fashion"}
          key="ogtitle"
        />
        <meta
          property="og:image"
          content="https://www.fleato.com/aboutfleato.png"
          key="ogtitle"
        />
        <meta
          property="og:url"
          content={"https://www.fleato.com"}
          key="ogurl"
        />
        <meta property="og:site_name" content={"Fleato.com"} key="ogsitename" />
        <meta
          property="og:description"
          content={
            "Become a part of the Fleato, the Web 3.0 Global Marketplace for Art & Fashion, and earn Fleato rewards and NFTs"
          }
          key="ogdesc"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ProductsProvider>
        <MobileNavigation />
        <SearchProvider searchScope={"home-recentDrops"}>
          <>
            {/* {!user && (localUser?.phone?.length === 0 || !localUser?.phone) && (
              <div
                className="mt-3 mb-3 cursor-pointer"
                onClick={() => openLoginPopup("signup5", true)}
              >
                <p className="text-blue leading-relaxed text-center">
                  <span className="font-bold">Holiday offer: </span>Earn $15
                  Fleato Rewards when you sign up today! Expires Feb 28
                </p>
              </div>
            )} */}
            {/* <div className="mt-3 mb-3 cursor-pointer"><Link href="/categories/labordaysale"><p className="text-blue leading-relaxed text-center"><span className="font-bold">Labor Day SALE </span> on all Brevart and Ivy Art Collections. Use Promo Code <span className="font-bold">LABORDAY</span> for an additional 20% off</p></Link></div> */}

            <div className="white-bg">
              <Banner isEvent={false} agency={agency} />
              <OffersWrapper>
                <div className="home-page-offer" ref={cardRef}>
                  <div className="container">
                    <div className="row mt-4 top-container justify-content-center">
                      <div
                        className=" col-12 col-lg-8 mb-lg-0 position-relative overflow-hidden position-relative"
                        style={{
                          backgroundImage: `url(${collectibles.src})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div className="p-4 h-100 d-md-flex justify-content-between mr-2 ">
                          {/* <video autoPlay src={"https://betirri.com/wp-content/uploads/2022/10/betirri-video.mp4"} playsInline loop muted style={{ position: "absolute", overflow: "hidden", zIndex: 0, direction: "ltr", top: 0, left: 0, width: '100%', height: '100%' }} /> */}
                          <div
                            className="glassmorphic w-100 h-100 position-absolute"
                            style={{ left: 0, top: 0 }}
                          ></div>
                          <div
                            className="col-sm-6 position-relative"
                            style={{ zIndex: 1 }}
                          >
                            <p className="mb-3 small-font text-white">
                              Art from around the World
                            </p>
                            <p className="mb-3 large-font text-white">
                              See our latest collections
                            </p>

                            <div>
                              <Link
                                href={`/categories/all?isNFT=true`}
                                passHref
                              >
                                <button className="btn white-ghost-btn">
                                  Explore
                                </button>
                              </Link>
                            </div>
                          </div>
                          {/* <div className='d-none d-md-block col-sm-6 mt-4 mt-md-0 position-absolute'
                            style={{ height: 400, right: 0, top: screenProps.windowSize.width >= 920 ? -200 : -100 }}
                          >
                            <img className='img-fluid' src={"https://cdn.fleato.com/pinned/home/thumbnails/artist-standing_680x680.jpg"} alt="mobile" />
                          </div>
                          <div className=' d-md-none col-sm-6 mt-4 mt-md-0'
                            style={{ height: 350 }}
                          >
                            <img className='img-fluid' src={"https://cdn.fleato.com/pinned/home/thumbnails/artist-standing_680x680.jpg"} alt="mobile" />
                          </div> */}
                        </div>
                      </div>
                      <div className="col-12 h-md-auto col-lg-4 d-flex flex-column flex-md-row flex-lg-column justify-content-between  px-0 mb-lg-0 bg-white position-relative">
                        <Link href={`/fleato-for?tab=forartists`} passHref>
                          <div className="h-md-100 h-50 w-md-50 me-md-1 me-lg-0 cursor-pointer w-100 w-lg-100 mb-1 mb-md-0 mb-lg-1 half-card position-relative">
                            <div
                              className={`h-100 w-100 `}
                            >
                              <div className="h-100  back-view"
                                style={{
                                  backgroundImage: `url(${fleatoArtistBG.src})`,
                                  backgroundSize: "cover",
                                }}>
                                <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                  <p className="mb-3 large-font-title text-white">
                                    Fleato for Art
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <Link href={`/fleato-for?tab=forevents`} passHref>
                          <div className="h-md-100 h-50 w-md-50 ms-md-1 ms-lg-0 cursor-pointer w-100 w-lg-100 mt-1 mt-md-0 mt-lg-1 half-card position-relative">
                            <div
                              className={`h-100 w-100 `}
                            >

                              <div className="h-100  back-view"
                                style={{
                                  backgroundImage: `url(${fleatoEventBG.src})`,
                                  backgroundSize: "cover",
                                }}>
                                <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                  <p className="mb-3 large-font-title text-white">
                                    Fleato for Events
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="container px-20 px-md-0 mt-3">
                  <h2 className="py-3 px-2">Success Stories</h2>
                  <div className="d-lg-flex mt-2 mb-4 bottom-container justify-content-center ">
                    <div className="col-12 h-md-auto col-lg-4 d-flex flex-column flex-md-row flex-lg-column justify-content-md-between  px-0 mb-lg-0 bg-white position-relative align-items-stretch mb-2">
                      <Link href={`/thunderball`} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-2 me-lg-0 cursor-pointer w-100 w-lg-100 mb-2 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >
                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${thunderballBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link href={`/save-the-artist`} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-0 me-lg-0 cursor-pointer w-100 w-lg-100 mb-0 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >

                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${savetheartistBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 h-md-auto col-lg-4 d-flex flex-column flex-md-row flex-lg-column justify-content-md-between  px-0 mb-lg-0 bg-white position-relative align-items-stretch mb-2">
                      <Link href={`/fresharts`} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-2 me-lg-0 cursor-pointer w-100 w-lg-100 mb-2 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >

                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${freshartsBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link href={'/betirri'} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-0 me-lg-0 cursor-pointer w-100 w-lg-100 mb-0 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >

                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${betirriBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-12 h-md-auto col-lg-4 d-flex flex-column flex-md-row flex-lg-column justify-content-md-between  px-0 mb-lg-0 bg-white position-relative align-items-stretch">
                      <Link href={`/lwb`} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-2 me-lg-0 cursor-pointer w-100 w-lg-100 mb-2 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >

                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${reggaeBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link href={`/bluetile`} passHref>
                        <div className="h-md-auto h-lg-100 h-50 w-md-50 me-md-0 me-lg-0 cursor-pointer w-100 w-lg-100 mb-0 mb-md-0 mb-lg-2 half-card position-relative">
                          <div
                            className={`h-100 w-100 `}
                          >

                            <div className="h-100  back-view"
                              style={{
                                backgroundImage: `url(${bluetileBG.src})`,
                                backgroundSize: "cover",
                              }}>
                              <div className="glassmorphic d-flex align-items-center justify-content-center w-100 h-100 p-4">
                                <p className="mb-3 large-font-title text-white">
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </OffersWrapper>
              <div className="py-3 container mt-3">
                <h2 >Featured Artists</h2>
              </div>
              {featuredArtists?.map((fa) => (
                fa?.artist?.stake >= 300 ? <div key={fa?.artist?.id}>
                  {fa?.artist?.displaySettings?.showCollectionsButton && (
                    <NewItems artist={fa?.artist} />
                  )}
                  {fa?.artist?.displaySettings?.showCollectionsButton && (
                    <ArtistProducts
                      artist={fa?.artist}
                      products={fa?.products}
                    />
                  )}
                  {/* {fa?.merch?.length > 1 &&
                    <MerchWrapper className="w-100 position-relative pt-4 mb-2 pt-md-0 mb-md-0">
                      <div className="mb-4 position-relative zIndex-1">
                        <h2 className="fw-normal text-center" >Discover Merchandise from {fa.artist.storeName}</h2>
                      </div>
                      <div className="merch-bg"></div>

                      <div className="merch-grid position-relative col-lg-8 mx-auto zIndex-1"
                        style={screenProps?.windowSize?.width >= 600 ?
                          fa.merch.sort((a, b) => a.order - b.order)?.length == 6 ?
                            { gridTemplateColumns: 'repeat(6,1fr)' }
                            : fa.merch.sort((a, b) => a.order - b.order)?.length == 5 ?
                              { gridTemplateColumns: 'repeat(5,1fr)' }
                              : { gridTemplateColumns: 'repeat(4,1fr)' }
                          : {}}>
                        {fa.merch.sort((a, b) => a.order - b.order).map(merch => (
                          <Link href={`/categories/${merch.facetTag}?artist=${merch.artistId}`} passHref key={merch.name}>
                            <div className="merch-grid-item" onClick={() => setClearSearchBox(true)}>
                              <div className="img-container border" style={{ backgroundImage: `url("${merch.image}")` }}>
                              </div>
                              <p className="title">{merch.name}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </MerchWrapper>
                  } */}
                </div>
                  : null))}

              {/* {isMobile ? <MapMobileView title={"Featured Art From Around the World"} /> : <MapComponent artists={artistsWithGeo} title={"Featured Art From Around the World"} />} */}
              <div className="py-3 container mt-3">
                <h2 >Meet Our Artists</h2>
              </div>
              <AllArtistSlider artists={allArtists.map((a) => a.artist)} />
              <div className="container py-5">
                <h1 className="text-center">Recent Drops</h1>
                <div className="col-12 col-md-8 py-3 text-center m-auto">
                  <RecentDropSearchBox />
                </div>
              </div>
            </div>
            <MainContentArea />
          </>
        </SearchProvider>
      </ProductsProvider >
    </div >
  );
};

export default Home;

interface MerchType {
  order: number;
  name: string;
  artistId: string;
  image: string;
  facetTag: string;
}

interface ArtistsApiResponse {
  artist: ArtistProfile;
  products: TypesenseProduct[];
  merch: MerchType[];
}

const baseUrl = fleatoConfig.websiteBaseUrl;
// const baseUrl = "http://localhost:3000";
export async function getServerSidePaths() {
  return { paths: ["/"], fallback: "blocking" };
}

interface HomePageGroupRecord {
  productName: string;
  productImage: string;
  tag: string;
}

interface HomePageGroupItem {
  rowOne: HomePageGroupRecord[];
  rowTwo: HomePageGroupRecord[];
}

interface HomePageGroups {
  groups: { item: HomePageGroupItem; title: string }[];
}

// export async function getStaticProps(params) {
//   // Fetch necessary data for the blog post using params.id
//   try {
//     const artistsResult = await fetch(`${baseUrl}/api/artists/fleato`);
//     const agencyResult = await fetch(`${baseUrl}/api/agency/fleato`);
//     const groups = await fetch(`${baseUrl}/api/homepage-groups`);
//     const artistsApiResponseText = await artistsResult.text();
//     const agency = JSON.parse(await agencyResult.text()) as Agency;
//     const groupsText = await groups.text();

//     let artistsApiResponse: ArtistsApiResponse[] = [];
//     let groupsResponse: HomePageGroups = null;
//     try {
//       artistsApiResponse = JSON.parse(artistsApiResponseText);
//       groupsResponse = JSON.parse(groupsText);
//     } catch (err) {
//       console.log("error", err, "response", artistsApiResponseText);
//     }
//     // console.log("allArtists", allArtists);
//     return {
//       props: {
//         allArtists: artistsApiResponse,
//         featuredArtists:
//           artistsApiResponse?.length > 0
//             ? [...artistsApiResponse]
//               .filter((a) => (a.artist.stake ?? 0) > 0)
//               .sort(
//                 (a, b) => (b?.artist?.stake ?? 0) - (a?.artist?.stake ?? 0)
//               )
//               ?.slice(0, 5)
//             : [],
//         groups: groupsResponse,
//         agency
//       },
//       revalidate: 10
//     };
//   } catch (err) {
//     console.log("Error loading data", err);
//     return {
//       props: {
//         allArtists: null,
//         featuredArtists: null,
//         groups: null,
//       },
//       revalidate: 10
//     }
//   }
// }

//getStaticPaths is only allowed for dynamic SSG pages and was found on '/'.
